// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useContext, useState, useEffect, Fragment } from "react"
import { useLocation } from "react-router-dom"
import { HashLink as Link } from "react-router-hash-link"
import { GrClose } from "react-icons/gr"

// Modules
import Button from "./Button"

// Context
import AppContext from "../../Context/App/appContext"

// ===============================================================
const AppNavbar = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { image_url, /*currentScroll, lastScroll,*/ windowDimensions, getNavbar, navbar } = appContext

	// ---------------------------------------------------------------
	// Active link highlighting
	const currentUrl = useLocation()

	const [isOpenPeople, setIsOpenPeople] = useState("none")
	const [isOpenAbout, setIsOpenAbout] = useState("none")
	const [isOpenXtra, setIsOpenXtra] = useState("none")
	const [isOpenSwitch, setIsOpenSwitch] = useState("none")


	// ---------------------------------------------------------------
	// Scroll effect for nav bar
	// useEffect(() => {
	// 	if (currentScroll <= 0) {
	// 		document.body.classList.remove('scroll-up')
	// 		return
	// 	}
	// 	if (currentScroll > lastScroll && !document.body.classList.contains('scroll-down')) {
	// 		document.body.classList.remove('scroll-up')
	// 		document.body.classList.add('scroll-down')
	// 		return
	// 	}
	// 	if (currentScroll < lastScroll && document.body.classList.contains('scroll-down')) {
	// 		document.body.classList.remove('scroll-down')
	// 		document.body.classList.add('scroll-up')
	// 		return
	// 	}
	// 	// eslint-disable-next-line
	// }, [currentScroll])

	// ---------------------------------------------------------------
	// Close the drawer when the user clicks outside of it
	// Fetch content
	useEffect(() => {
		getNavbar()

		// eslint-disable-next-line
	}, [])

	// 100% is closed, 0% is open
	const [menuIsOpen, setMenuIsOpen] = useState(false)

	const regPeople = /^\/for-people*/
	const regBusiness = /^\/for-business*/
	const regSwitch = /^\/switch-to-us*/
	const regFees = /^\/account-fees-and-limits*/
	const regHelp = /^\/help*/
	const regAbout = /^\/about*/


	const drawerToggle = (w = windowDimensions, open = menuIsOpen) => {
		if (w < 1000) {
			if (open) {
				return "translateX(0%)"
			} else {
				return "translateX(100%)"
			}
		} else if (w > 1000) {
			return "unset"
		}
	}

	return (
		<Fragment>
			{navbar && (
				<nav className="nav__nav-wrapper">
					<div className="nav-logo-wrapper" id="navbar">
						{/* Top-right small logo for desktop */}
						<a
							href="https://www.shopriteholdings.co.za"
							target="_blank"
							rel="noreferrer"
							className="shoprite-desktop"
						>
							<img
								src={`${image_url}${navbar.navbarSubLogo[0].id}`}
								alt={navbar.navbarSubLogo[0].metadata.alt}
							/>
							{navbar.navbarSubText}
						</a>

						{/* Top small logo for mobile */}
						<a
							href="https://www.shopriteholdings.co.za"
							target="_blank"
							rel="noreferrer"
							className="shoprite-mobile"
						>
							<img
								src={`${image_url}${navbar.navbarSubLogo[0].id}`}
								alt={navbar.navbarSubLogo[0].metadata.alt}
							/>
							{navbar.navbarSubText}
						</a>
					</div>
					<div className="nav-wrapper">
						<div className="nav-logo">
							<Link smooth to="/">
								<img
									className="nav-logo-left"
									src={`${image_url}${navbar.navbarLogo[0].id}`}
									alt={navbar.navbarLogo[0].metadata.alt}
								/>
							</Link>
						</div>

						<button
							className="hamburger-button-wrapper"
							onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
						>
							<div className="hamburger-button-lines"></div>
						</button>

						<ul className="nav-items" style={{ transform: `${drawerToggle()}` }}>
							{windowDimensions < 1000 && (
								<GrClose
									className="btn-close"
									size={"25px"}
									onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
								/>
							)}

							<li
								className="nav-item"
								onMouseEnter={() => setIsOpenPeople("block")}
								onMouseLeave={() => setIsOpenPeople("none")}
							>
								<Link
									smooth
									style={{ position: "relative" }}
									to="/for-people"
									className={
										currentUrl.pathname === "/"
											? "active-link"
											: "" || currentUrl.pathname.match(regPeople)
												? "active-link"
												: ""
									}
									onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
								>
									<span className="no-break">For People</span>
								</Link>
								<ul className="navbar-sub-list" style={{ display: isOpenPeople }}>
									<li>
										<Link
											to="/for-people/how-to-use-cash-wallet"
											onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
											className={currentUrl.pathname.match(regPeople) ? "active-link" : ""}
										>
											How to use
										</Link>
									</li>
									<li>
										<Link
											to="/for-people/where-to-use"
											onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
											className={currentUrl.pathname.match(regPeople) ? "active-link" : ""}
										>
											Where to use
										</Link>
									</li>
									<li>
										<Link
											to="/for-people/xtra-savings-linking"
											onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
											className={currentUrl.pathname.match(regPeople) ? "active-link" : ""}
										>
											Link Xtra Savings card
										</Link>
									</li>
									<li>
										<a
											href="https://send.moneymarketaccount.co.za/"
											target="_blank"
											rel="noreferrer"
											className={`${currentUrl.pathname.match(regPeople) ? "active-link" : ""
												}} text-red-dark`}
											onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
										>
											Send vouchers
										</a>
									</li>
									<li>
										<Link
											to="/register"
											className={` ${currentUrl.pathname.match(regPeople) ? "active-link" : ""
												}} text-purple-dark`}
											onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
										>
											Register
										</Link>
									</li>
								</ul>
							</li>
							<li className="nav-item">
								<Link
									smooth
									to="/for-business"
									className={currentUrl.pathname.match(regBusiness) ? "active-link" : ""}
									onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
								>
									<span className="no-break">For Business</span>
								</Link>
							</li>
							<li className="nav-item"
							onMouseEnter={() => setIsOpenSwitch("block")}
							onMouseLeave={() => setIsOpenSwitch("none")}
							>
								<Link
									smooth
									to="/switch-to-us"
									className={currentUrl.pathname.match(regSwitch) ? "active-link" : ""}
									onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
								>
									<span className="no-break">Switch to us</span>
								</Link>
								<ul className="navbar-sub-list" style={{ display: isOpenSwitch }}>
									<li>
										<Link
											to="/unclaimed"
											onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
										>
											Unclaimed Benefits
										</Link>
									</li>
								</ul>
							</li>

							<li
								className="nav-item"
								onMouseEnter={() => setIsOpenXtra("block")}
								onMouseLeave={() => setIsOpenXtra("none")}
							>
								<Link
									smooth
									to="/xtra-savings-linking"
									className={currentUrl.pathname.match(regAbout) ? "active-link" : ""}
									onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
								>
									<span className="no-break">Xtra Savings</span>
								</Link>
								<ul className="navbar-sub-list" style={{ display: isOpenXtra }}>
									<li>
										<Link
											to="/cashback"
											onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
										>
											Cashback deals
										</Link>
									</li>
									<li>
										<Link to="/deals" onClick={() => setMenuIsOpen((currentVal) => !currentVal)}>
										Exclusive deals
										</Link>
									</li>
								</ul>
							</li>
							<li className="nav-item">
								<Link
									smooth
									to="/account-fees-and-limits"
									className={currentUrl.pathname.match(regFees) ? "active-link" : ""}
									onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
								>
									<span className="no-break">Fees &amp; limits</span>
								</Link>
							</li>
							<li className="nav-item">
								<Link
									smooth
									to="/help"
									className={currentUrl.pathname.match(regHelp) ? "active-link" : ""}
									onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
								>
									<span className="no-break">Help</span>
								</Link>
							</li>
							<li
								className="nav-item"
								onMouseEnter={() => setIsOpenAbout("block")}
								onMouseLeave={() => setIsOpenAbout("none")}
							>
								<Link
									smooth
									to="/about"
									className={currentUrl.pathname.match(regAbout) ? "active-link" : ""}
									onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
								>
									<span className="no-break">About</span>
								</Link>
								<ul className="navbar-sub-list" style={{ display: isOpenAbout }}>
									<li>
										<Link
											to="/about/account-security"
											onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
										>
											Security
										</Link>
									</li>
									<li>
										<Link to="/about/banking" onClick={() => setMenuIsOpen((currentVal) => !currentVal)}>
											Account Ts&amp;Cs
										</Link>
									</li>
								</ul>
							</li>
							{/* <div className='nav-btn-container'> */}
							{navbar.navbarButton.map((button) => {
								return (
									<li className="nav-item" key={button.id}>
										<Button
											button={button}
											optClass="nav-button-right btn-nav"
											onClick={() => setMenuIsOpen((currentVal) => !currentVal)}
										/>
									</li>
								)
							})}
							{/* </div> */}
						</ul>
					</div>
				</nav>
			)}
		</Fragment>
	)
}

export default AppNavbar
